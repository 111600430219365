@tailwind base;
@tailwind components;
@tailwind utilities;

ul li a {
  @apply rounded py-[7px] px-[13] text-[17px]
  hover:bg-slate-300 transition-all duration-300;
}

#check:checked ~ ul {
  left: 0;
}
